import React from 'react';
import { useRecoilValue } from 'recoil';
import { totalScreenState } from '../MobileFrame';
import { RenewalButtonStyle } from '../../RenewalButtonStyle';
import { Link } from 'react-router-dom';
import { css } from 'aphrodite';
import { menuPage } from '../HistoryHelper';
import { ReactComponent as MenuIcon } from '../icons/menu.svg';

export const RenewalMenuButton = () => {
  const {isMobile, isTablet, isTop} = useRecoilValue(totalScreenState);
  const styles = RenewalButtonStyle({
    isMobile, isTablet
  });

  return (
    <>
      {(isTablet || isMobile) && (
        <Link
          className={css(styles.button, isTop && styles.button_top)}
          to={menuPage}
        >
          <MenuIcon className={css(styles.buttonIcon)}/>
        </Link>
      )}
    </>
  );
};
