import React from 'react';
import { css } from 'aphrodite';
import CountUp from 'react-countup';
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';

import { robotoMedium, spoqa } from '../../../../shared/css/css-utils';
import { sharedShape } from '../../../../shared/css/SharedStyleSheet';
import { Assets } from '../../shared/Assets';
import { Image } from '../../../../shared/css/Image';
import { requestPage } from '../../../../shared/presentation/HistoryHelper';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';

function Main() {
  const {isMobile, isDesktop, isTablet} = useRecoilValue(totalScreenState);
  const styles = MainStyle(isMobile, isTablet);

  return (
    <div className={css(styles.background)} id={'section_1'}>
      {isDesktop &&
      <video autoPlay loop playsInline muted className={css(styles.videoBox)} id={'video'}>
        <source src={Assets.MainVideo} type="video/mp4"/>
      </video>
      }
      <div className={css(styles.contentBox)}>
        <div className={css(styles.leftBox)}>
          {isDesktop ?
            <>
              <div className={css(styles.firstFont)}>
                개인사업자의 법인전환 100% 무료, 법인전환지원센터
              </div>
              <div className={css(styles.secondFont)}>
                {'KOREA \nNO.1'}
              </div>
              <div className={css(styles.thirdFont)}>
                법인전환지원센터에서 최고의 전문가들을 통해 무료로 법인전환하세요.
              </div>
            </>
            :
            <>
              <Image
                src={Assets.MainBack(isMobile)}
                className={css(sharedShape.background)}
                alt="메인"
              />
              <div className={css(styles.firstFont)}>
                개인사업자의 법인전환 100% 무료<br/>
                전국 어디에서나 간편하게
              </div>
              <div className={css(styles.secondFont)}>
                {'KOREA \nNO.1'}
              </div>
              <div className={css(styles.thirdFont)}>
                법인설립지원센터에서 전문가들을 통해 무료로 법인전환하고, <br/>
                수 많은 세무사 중 가장 잘 맞는 세무사를 선택하세요.
              </div>
            </>
          }
          <Link className={css(styles.requestButton)} to={requestPage()} id={'request_button'}>
            무료 법인전환 신청하기
          </Link>
        </div>

        {isDesktop ?
          <div className={css(styles.rightBox)}>
            <div className={css(styles.rightFirstFont)}>
              법인전환, <br/>
              <span style={{color: '#06b3cb'}}>신뢰할 수 있는 곳</span>과 함께하세요.
            </div>
            <div className={css(styles.titleFont)}>
              이용 고객수 :
            </div>
            <div className={css(styles.numberFont)}>
              <CountUp end={43024} separator=","/>
              명
            </div>
            <div className={css(styles.titleFont)}>
              누적 자본금 :
            </div>
            <div className={css(styles.numberFont)}>
              <CountUp end={32790000000} separator=","/>
              원
            </div>
            <div className={css(styles.titleFont)}>
              고객 만족도 :
            </div>
            <div className={css(styles.numberFont)}>
              <CountUp end={95.3} separator="," decimals={1} decimal="."/>
              점
            </div>
          </div>
          :
          <div className={css(styles.rightBox)}>
            <Image
              src={Assets.MainBackground02}
              className="background"
              alt="영업권평가_배경"/>
            <div className={css(styles.awardImageBox)}>
              <Image
                src={Assets.Award1}
                className={css(styles.awardImageStyle)}
                alt="상"/>
              <Image
                src={Assets.Award2}
                className={css(styles.awardImageStyle)}
                alt="상"
              />
            </div>
            <div className={css(styles.numberBox)}>
              <div className={css(styles.titleFont)}>
                누적 자본금&nbsp;
              </div>
              <div className={css(styles.numberFont)}>
                <CountUp end={327} separator=","/>
                억&nbsp;
              </div>
              <div className={css(styles.numberFont)}>
                <CountUp end={9000} separator=","/>
                만원
              </div>
            </div>
            <div className={css(styles.numberBox)}>
              <div className={css(styles.titleFont)}>
                이용 고객수&nbsp;
              </div>
              <div className={css(styles.numberFont)}>
                <CountUp end={43024} separator=","/>
                명
              </div>
            </div>
            <div className={css(styles.numberBox)}>
              <div className={css(styles.titleFont)}>
                고객 만족도&nbsp;
              </div>
              <div className={css(styles.numberFont)}>
                <CountUp end={95.3} separator="," decimals={1} decimal="."/>
                점
              </div>
            </div>
            <div className={css(styles.awardFont)}>
              Award Winning Korea Marketing Forum
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default Main;

const MainStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    width: '100%',
    height: '/100vh',

    fontFamily: spoqa,

    overflow: 'hidden',

    position: 'relative',

    padding: '/23.26vw 1.7vw 2.43vw 7.43vw',

    overflowX: 'hidden',
  },
  contentBox: {
    width: '100%',
    height: '100%',

    position: 'relative',

    display: 'flex',
    flexDirection_: 'column/',
    justifyContent_: '/space-between',
    alignItems_: 'center/flex-end',
  },
  videoBox: {
    display_: 'none/',
    position: 'absolute' as 'absolute',
    transform: 'translateX(-50%) translateY(-50%)',

    left: '50%',
    top: '50%',

    minWidth: '100%',
    minHeight: '100%',
    zIndex: -1,
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems_: 'center/',

    position_: 'relative/',

    width: '100vw/',
    height: '728px/',

    paddingTop: '208px/',
    // paddingBottom: '/5.7vw',
    paddingBottom: '/60px',

    color: 'white',
  },
  firstFont: {
    marginBottom: '10px/0.6vw',
    marginTop: '/3.9vw',

    fontWeight: 300,
    fontSize: '24px/1.38vw',
    lineHeight: '1.42/1',
    textAlign_: 'center/',
  },
  secondFont: {
    fontFamily: 'Roboto',
    fontSize: '85px/8vw',
    fontWeight: 900,
    lineHeight: '.9/1',
    textAlign_: 'center/',

    marginBottom: '9px/',
    whiteSpace_: 'pre/nowrap',
  },
  thirdFont: {
    marginBottom: '21px/1.7vw',

    fontWeight_: '400/300',
    fontSize: '14px/1.11vw',
    lineHeight: '1.71/1.69',
    textAlign_: 'center/',
  },
  requestButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: '307px/18.54vw',
    height: '50px/3.47vw',

    fontSize: '14px/0.97vw',
    fontWeight_: '500/300',

    border: 'solid 1px white',

    cursor: 'pointer',

    ':hover': {
      backgroundColor: 'white',
      color: '#051a43',
      transition: '0.3s',
    },
  },
  rightBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent_: '/center',
    alignItems_: 'center/',

    position_: 'relative/',

    width: '100%/17.91vw',
    height: '358px/22.4vw',

    paddingLeft: '/1.66vw',
    paddingTop: '50px/',
    marginTop: '/4.4vw',

    backgroundColor: 'transparent/white',

    fontWeight: 300,
  },
  rightFirstFont: {
    fontSize: '/1.1vw',
    lineHeight: '/1.69',
    color: '#051a43',
  },
  titleFont: {
    marginTop: '/0.9vw',
    marginBottom: '/2px',

    fontSize: '25px/1.1vw',
    lineHeight: '1.42vw/1.69vw',
    color: '#051a43/#838ba3',
  },
  numberFont: {
    fontWeight_: '700/300',
    fontSize: '25px/1.5vw',
    color: '#051a43/#06b3cb',
  },
  numberBox: {
    display: 'flex',
    marginBottom: '5px/',
    alignItems_: 'center/',
  },
  awardImageBox: {
    width: '270px/',

    display: 'flex',
    justifyContent: 'space-between',

    marginBottom: '30px/',
  },
  awardImageStyle: {
    width: '124px/',
    height: '75px/',
  },
  awardFont: {
    fontFamily: robotoMedium,
    fontSize: '12px/',
    fontWeight: 500,
    color: '#051a43',

    marginTop: '9px/',
  },
}, isMobile, isTablet);
