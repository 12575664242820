import { css } from 'aphrodite';
import React from 'react';
import { useAtom } from 'jotai';

import { questionAtom } from './store/RequestAtom';
import { requestErrorAtom } from './store/RequestErrorAtom';

interface IQuestionInputProps {
  styles: { textAreaBox: Object, textArea: Object, error: Object }
}

export function QuestionInput({ styles }: IQuestionInputProps) {
  const [question, setQuestion] = useAtom(questionAtom);
  // const [request, setRequest] = useRecoilState(requestState);
  const [errors, setErrors] = useAtom(requestErrorAtom);

  const onChangeQuestion = (question: string) => {
    // setRequest({ ...request, question })
    setQuestion(question);
    setErrors({ ...errors, isQuestionError: false })
  }

  return (
    <div className={css(styles.textAreaBox)}>
      <textarea
        className={css(styles.textArea)}
        placeholder={'문의사항'}
        value={question}
        onChange={e => onChangeQuestion(e.target.value)}
        id={'text_textarea'}
      />
      {errors.isQuestionError && (
        <div className={css(styles.error)}>
          {'문의사항을 입력해주세요'}
        </div>
      )}
    </div>
  )
}
