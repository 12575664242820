import React from 'react';
import SharedLogo from './SharedLogo';

interface Props {
  LogoImage: any,
  baseColor?: string,
}

export const RenewalLogo = (
  {
    baseColor,
    LogoImage,
  }: Props) => {
  return (
    <SharedLogo
      styles={{
        logo: {
          position: 'relative',
          top: '-10.5px',

          marginLeft: '10px/10px',
          marginRight: '/10px',
        },
        logoIcon: {
          width: '/230px',
          height: '/41px',
        },
        logoIconTop: {
          width: '/230px',
          height: '/41px',
        },
      }}
      LogoImage={LogoImage}
      baseColor={baseColor}
    />
  );
}
