import { css } from 'aphrodite';
import React from 'react';
import { useAtom } from 'jotai';

import { REQUEST_SERVICE, RequestError, RequestService, } from './store/RequestStore';
import { useInjection } from './presentation/InversifyProvider';
import { phoneAtom } from './store/RequestAtom';
import { requestErrorAtom } from './store/RequestErrorAtom';

interface IPhoneInputProps {
  styles: { inputBox: Object, input: Object, error: Object }
}

export function PhoneInput({ styles }: IPhoneInputProps) {
  const [phone, setPhone] = useAtom(phoneAtom);
  const [errors, setErrors] = useAtom(requestErrorAtom);
  const requestService = useInjection<RequestService>(REQUEST_SERVICE);

  const onChangePhone = (phone: string) => {
    const result = requestService.setPhoneNumber(phone);
    if (result.error) {
      setErrors(RequestError.create({
        ...errors,
        ...result.error,
      }));
      return;
    }
    setPhone(phone);
    setErrors(RequestError.create({ ...errors, isPhoneEmpty: false, isPhoneError: false }))
  };

  return (
    <div className={css(styles.inputBox)}>
      <input
        placeholder='휴대폰 번호'
        className={css(styles.input)}
        onChange={e => onChangePhone(e.target.value)}
        value={phone}
        data-testid='phone'
        id={'number_input'}
      />
      {errors.isPhoneError
        ? <div className={css(styles.error)}>정확한 번호를 입력해주세요</div>
        : errors.isPhoneEmpty
        && <div className={css(styles.error)}>휴대폰 번호를 입력해주세요</div>
      }
    </div>
  )
}
