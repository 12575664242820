import axios from 'axios';
import Cookies from 'universal-cookie';
import { atom } from 'recoil';
import { inject, injectable } from 'inversify';

import { KAKAO_MESSAGE_SENDER, KakaoMessageSender } from '../domain/KakaoMessageSender';
import { createTaxagentCode } from '../helper/RequestHelper';
import { TaxagentCrawlingRepository, TotalIncomes } from '../domain/TaxagentCrawlingRepository';
import { SCRIPT_LOGGER, ScriptLogger } from '../domain/ScriptLogger';
import { getAuthIdFromKakaoLogin } from '../helper/KakaoLoginHelper';
import { TAX_AGENT_REQUEST_REPOSITORY, TaxagentRequestRepository } from '../domain/TaxagentRequestRepository';

const HOMETAX_LOGIN_API_URL = 'https://ul5v11zxkj.execute-api.ap-northeast-2.amazonaws.com/default/hometaxLOGIN';
const RECORD_ID_EXPIRE_TIME = 60 * 30;

export const taxagentRequestState = atom<ITaxagentRequestForm>({
  key: 'taxagentRequestState',
  default: {
    name: '',
    phone: '',
    isLateTimeContact: false,
    isFreeBiz: false,
    isPrivateBiz: false,
    isOtherBiz: false,
    isAdditionalIncome: false,
    isDependents: false,
    recommandee: '',
  },
});

interface ITaxagentRequestForm {
  name: string,
  phone: string,
  isLateTimeContact: boolean,
  isFreeBiz: boolean,
  isPrivateBiz: boolean,
  isOtherBiz: boolean,
  isAdditionalIncome: boolean,
  isDependents: boolean,
  recommandee: string,
}

export const ID_NOT_EXIST = 'Id is not exist!!';
export const USER_NOT_EXIST = 'User not exist error!!';
export const INFO_ERROR = 'info error';

export const authIdState = atom<string>({
  key: 'authIdState',
  default: '',
})

export const requestForCertState = atom<any>({
  key: 'requestForCertState',
  default: undefined,
})

export const totalIncomesState = atom<TotalIncomes>({
  key: 'totalIncomesState',
  default: {
    name: '',
    totalIncome: '0',
    types: [],
  }
});

export const TAX_AGENT_REQUEST_SERVICE = Symbol('TAX_AGENT_REQUEST_SERVICE');

@injectable()
export class TaxagentRequestService {
  cookies = new Cookies();

  constructor(
    @inject(TAX_AGENT_REQUEST_REPOSITORY) private readonly requestRepository: TaxagentRequestRepository,
    @inject(KAKAO_MESSAGE_SENDER) private readonly kakaoMessageSender: KakaoMessageSender,
    private readonly crawlingRepository: TaxagentCrawlingRepository,
    @inject(SCRIPT_LOGGER) private readonly logger: ScriptLogger,
  ) {
  }

  checkRequest(request: ITaxagentRequestForm): string {
    if (request.name === '') {
      return '이름을 입력해주세요.';
    }

    if (request.phone === '') {
      return '전화번호를 입력해주세요.';
    }

    return 'success';
  }

  public async sendBasicInfo(keyword: string, request: ITaxagentRequestForm) {
    this.logger!.sendRequestOccuredLog();
    const existingRequest = await this.requestRepository.findOneRequestWithBasicInfo(request.phone, request.name);
    if (existingRequest != null) {
      const recordId = await this.requestRepository.updateTaxagentRequestBasicInfo({
        id: existingRequest.id,
        declareType: this.getDeclareType(request),
        isDependents: request.isDependents,
        recommendCode: request.recommandee ?? '',
        isLateTimeContact: request.isLateTimeContact,
      });
      this.cookies.set('customer_record_id', recordId, { maxAge: RECORD_ID_EXPIRE_TIME });
      return;
    }

    const exist_res = await axios.post(process.env.REACT_APP_API_BASE_URL + '/taxagent/exist-code', {
      phone: request.phone,
    });
    const code = exist_res.data !== '' ? exist_res.data : await createTaxagentCode('21');
    const recordId = await this.requestRepository.addTaxagentRequestBasicInfo({
      name: request.name,
      phone: request.phone,
      declareType: this.getDeclareType(request),
      isDependents: request.isDependents,
      recommendCode: request.recommandee ?? '',
      isLateTimeContact: request.isLateTimeContact,
      code: code.toString(),
      keyword,
    });
    this.cookies.set('customer_record_id', recordId, { maxAge: RECORD_ID_EXPIRE_TIME });
  }

  private getDeclareType(request: ITaxagentRequestForm) {
    let type: string[] = [];
    if (request.isFreeBiz) {
      type.push('프리랜서');
    }
    if (request.isPrivateBiz) {
      type.push('개인사업자');
    }
    if (request.isOtherBiz) {
      type.push('기타소득');
    }
    if (request.isAdditionalIncome) {
      type.push('근로소득');
    }
    return type;
  }

  public async loginHometax(id: string, password: string): Promise<boolean> {
    const result = await axios.post(
      HOMETAX_LOGIN_API_URL,
      {
        userId: id,
        pswd: password,
      }
    );
    return result.data.body === '"Success"';
  }

  public async crawlHomeTaxLoginInfo(props: { id: string, homeTaxId: string, homeTaxPassword: string }): Promise<TotalIncomes> {
    const { id, homeTaxId, homeTaxPassword } = props;
    if (id === '') {
      throw new Error(ID_NOT_EXIST);
    }
    const existingRequest = await this.requestRepository.findOneRequestWithRequestId(id);
    if (existingRequest != null) {
      await this.requestRepository.updateTaxagentHomeTaxLoginInfo({
        id: existingRequest.id,
        homeTaxId: homeTaxId,
        homeTaxPassword: homeTaxPassword,
      });

      return await this.crawlingRepository.getCrawlingInfoWithRecordId(existingRequest.id);
    }

    throw new Error(USER_NOT_EXIST);
  }

  public async sendConfirmRequestMessage(id: string, request: ITaxagentRequestForm) {
    if (id === '') {
      throw new Error(ID_NOT_EXIST);
    }
    const existingRequest = await this.requestRepository.findOneRequestWithRequestId(id);

    if (existingRequest != null) {
      const exist_res = await axios.post(process.env.REACT_APP_API_BASE_URL + '/taxagent/exist-code', {
        phone: request.phone,
      });
      const code = exist_res.data !== '' ? exist_res.data : existingRequest.code;
      await this.kakaoMessageSender.sendConfirmRequestMessage(request.name, request.phone, addLinkToCode(code));
    }

    throw new Error(USER_NOT_EXIST);
  }

  public async getBasicInfoWithId(id: string): Promise<{ name: string, phone: string, code: string }> {
    if (id == null) {
      throw new Error(ID_NOT_EXIST);
    }
    const result = await this.requestRepository.findOneRequestWithRequestId(id);

    if (result == null) {
      throw new Error(USER_NOT_EXIST)
    }

    return result;
  }

  public async sendHomeTaxKakaoLoginInfo(input: {
    id: string,
    name: string,
    phone: string,
    ssn1: string,
    ssn2: string,
  }): Promise<string> {
    const { id, name, phone, ssn1, ssn2 } = input;
    if (id === '') {
      throw new Error(ID_NOT_EXIST);
    }
    const existingRequest = await this.requestRepository.findOneRequestWithRequestId(id);
    if (existingRequest == null) {
      throw new Error(USER_NOT_EXIST);
    }
    await this.requestRepository.updateTaxagentHomeTaxKakaoLoginInfo({
      id: existingRequest.id,
      personalNumber: `${ssn1}-${ssn2}`,
    });

    const authId = await getAuthIdFromKakaoLogin({
      name,
      phone,
      ssn1,
      ssn2,
    });

    if (authId.indexOf('incorrect') !== -1) {
      throw new Error(INFO_ERROR);
    }
    return authId;
  }

  public checkHomeTaxKakaoRequest(input: {
    name: string,
    phone: string,
    ssn1: string,
    ssn2: string,
  }): string {
    const { name, phone, ssn1, ssn2 } = input;
    if (name === '') {
      return '이름을 입력해주세요.';
    }

    if (phone === '') {
      return '전화번호를 입력해주세요.';
    }

    if (ssn1 === '' || ssn2 === '') {
      return '주민등록번호를 입력해주세요.';
    }

    if (ssn1.length !== 6) {
      return '주민등록번호 앞자리를 확인해주세요.';
    }

    if (ssn2.length !== 7) {
      return '주민등록번호 뒷자리를 확인해주세요.';
    }

    return 'success';
  }

  public async sendUploadedFileInfo(input: { name: string, phone: string, noticeFiles: UploadedFile[] }) {
    const { name, phone, noticeFiles } = input;
    const existingRequest = await this.requestRepository.findOneRequestWithBasicInfo(phone, name);
    if (existingRequest == null) {
      throw new Error(USER_NOT_EXIST);
    }

    await this.requestRepository.updateTaxagentUploadFileInfo({
      id: existingRequest.id,
      files: noticeFiles.map(file => file.url),
    });

    const exist_res = await axios.post(
      process.env.REACT_APP_API_BASE_URL + '/taxagent/exist-code',
      {
        phone,
      });
    const code = exist_res.data !== '' ? exist_res.data : existingRequest.code;
    await this.kakaoMessageSender.sendConfirmRequestMessage(name, phone, addLinkToCode(code));
  }

  public async sendAddInfoFile(id: string, uploadedFiles: UploadedFile[]) {
    await this.requestRepository.updateTaxagentRequestAddInfoFile({
      id,
      otherFiles: uploadedFiles.map(file => file.url),
    });
  }

  public async sendAddInfo(input: {
    id: string,
    bank: string,
    accountNumber: string,
    depositorName: string,
    email: string,
    personalExemption: string,
    note: string,
    uploadedFiles: UploadedFile[],
  }) {
    const request = await this.requestRepository.findOneRequestWithRequestId(input.id);
    await this.requestRepository.updateTaxagentRequestAddInfo({
      name: request!.name,
      phone: request!.phone,
      code: request!.code,
      bankName: input.bank,
      accountNumber: input.accountNumber,
      depositorName: input.depositorName,
      email: input.email,
      personalExemptionInfo: input.personalExemption,
      otherFiles: input.uploadedFiles.map(file => file.url),
      note: input.note,
    });
  }

  public async sendAddInfoPersonal(input: {
    id: string,
    bank: string,
    accountNumber: string,
    depositorName: string,
    email: string,
    personalExemption: string,
    note: string,
    laborCost: string,
    rent: string,
    taxAdjustmentFiles: UploadedFile[],
    otherFiles: UploadedFile[],
  }) {
    const laborCost = parseInt(input.laborCost);
    const rent = parseInt(input.rent);
    const request = await this.requestRepository.findOneRequestWithRequestId(input.id);
    await this.requestRepository.updateTaxagentRequestAddInfoPersonal({
      name: request!.name,
      phone: request!.phone,
      code: request!.code,
      bankName: input.bank,
      accountNumber: input.accountNumber,
      depositorName: input.depositorName,
      email: input.email,
      personalExemptionInfo: input.personalExemption,
      laborCost,
      rent,
      taxAdjustmentFiles: input.taxAdjustmentFiles.map(file => file.url),
      otherFiles: input.otherFiles.map(file => file.url),
      note: input.note,
    });
  }
}

function addLinkToCode(code: string): string {
  const site = process.env.REACT_APP_COMPANY_SITE!.replace('https://', '');
  return `${code}\n⦁ 고유링크 : ${site}/code/${code}\n\n(고유링크로 신청 시 추천인코드 자동 입력)`;
}

export interface UploadedFile {
  url: string,
  original_filename: string,
  format: string,
}

// export class TaxagentRequestStore {
//   cookies = new Cookies();
//
//   constructor(
//     private readonly requestRepository: RequestRepository,
//     private readonly kakaoMessageSender: KakaoMessageSender,
//     private readonly phoneFormatPolicy: PhoneFormatPolicy,
//     private readonly crawlingRepository: TaxagentCrawlingRepository,
//     private readonly logger: ScriptLogger,
//   ) {
//   }
//
//   @observable
//   isShowSuccessPopUp: boolean = false;
//
//   @observable
//   isLateTimePopUp: boolean = false;
//
//   @action
//   openLatePopUp() {
//     this.isLateTimePopUp = true;
//   }
//
//   @action
//   closeLatePopUp() {
//     this.isLateTimePopUp = false;
//   }
//
//   @observable
//   isLateTimeContact: boolean = false;
//
//   @action
//   setLateTimeContact(value: boolean) {
//     this.isLateTimeContact = value;
//   }
//
//   @observable
//   isLoading: boolean = false;
//
//   @observable
//   name: string = '';
//
//   @observable
//   phone: string = '';
//
//   @computed
//   get firstInfoList(): string[] {
//     return [
//       this.name,
//       this.phone
//     ];
//   }
//
//   @observable
//   customerType: string = '';
//
//   @observable
//   declareTypes: boolean[] = [
//     false,
//     false,
//     false,
//     false
//   ];
//
//   @computed
//   get isFreeBiz(): boolean {
//     return this.declareTypes[0];
//   }
//
//   @computed
//   get isPrivateBiz(): boolean {
//     return this.declareTypes[1];
//   }
//
//   @computed
//   get isOtherBiz(): boolean {
//     return this.declareTypes[2];
//   }
//
//   @computed
//   get isAdditionalIncome(): boolean {
//     return this.declareTypes[3];
//   }
//
//   @observable
//   noticeFiles: UploadedFile[] = [];
//
//   @observable
//   otherFiles: UploadedFile[] = [];
//
//   @observable
//   recommandee?: string = '';
//
//   @observable
//   etc?: string = '';
//
//   @action
//   closePopUp() {
//     this.isShowSuccessPopUp = false;
//   }
//
//   @action
//   setName(name: string) {
//     this.name = name;
//   }
//
//   @action
//   setPhone(phone: string) {
//     this.phone = this.phoneFormatPolicy.changeFormat(phone);
//   }
//
//   @action
//   addNoticeFiles(files: UploadedFile[]) {
//     this.noticeFiles = [...this.noticeFiles, ...files];
//   }
//
//   @action
//   deleteNoticeFiles(index: number) {
//     this.noticeFiles.splice(index, 1);
//     this.noticeFiles = [...this.noticeFiles];
//   }
//
//   @action
//   addOtherFiles(files: UploadedFile[]) {
//     this.otherFiles = [...this.otherFiles, ...files];
//   }
//
//   @action
//   deleteOtherFiles(index: number) {
//     this.otherFiles.splice(index, 1);
//     this.otherFiles = [...this.otherFiles];
//   }
//
//
//   @action
//   setBasicInfo(index: number, info: string) {
//     if (index === 0) {
//       this.setName(info);
//     } else if (index === 1) {
//       this.setPhone(info);
//     }
//   }
//
//   @action
//   setDeclareTypes(index: number, isChecked: boolean) {
//     this.declareTypes[index] = isChecked;
//   }
//
//   @computed
//   get getDeclareType() {
//     let type: string[] = [];
//     if (this.isFreeBiz) {
//       type.push('프리랜서');
//     }
//     if (this.isPrivateBiz) {
//       type.push('개인사업자');
//     }
//     if (this.isOtherBiz) {
//       type.push('기타소득');
//     }
//     if (this.isAdditionalIncome) {
//       type.push('근로소득');
//     }
//     return type;
//   }
//
//   @action
//   setRecommandee(recommandee: string) {
//     this.recommandee = recommandee;
//   }
//
//   @action
//   setEtc(etc: string) {
//     this.etc = etc;
//   }
//
//   @action
//   public async sendRequestBasicInfo(keyword: string) {
//     this.startLoading();
//     this.logger!.sendRequestOccuredLog();
//     const existingRequest = await this.requestRepository.findOneRequestWithBasicInfo(this.phone, this.name);
//     if (existingRequest != null) {
//       const recordId = await this.requestRepository.updateTaxagentRequestBasicInfo({
//         id: existingRequest.id,
//         declareType: this.getDeclareType,
//         isDependents: this.isDependents,
//         recommendCode: this.recommandee ?? '',
//         isLateTimeContact: this.isLateTimeContact,
//       });
//       this.cookies.set('customer_record_id', recordId, { maxAge: RECORD_ID_EXPIRE_TIME });
//       this.stopLoading();
//       return;
//     }
//
//     const exist_res = await axios.post(process.env.REACT_APP_API_BASE_URL + '/taxagent/exist-code', {
//       phone: this.phone,
//     });
//     const code = exist_res.data !== '' ? exist_res.data : await createTaxagentCode('21');
//     const recordId = await this.requestRepository.addTaxagentRequestBasicInfo({
//       name: this.name,
//       phone: this.phone,
//       declareType: this.getDeclareType,
//       isDependents: this.isDependents,
//       recommendCode: this.recommandee ?? '',
//       isLateTimeContact: this.isLateTimeContact,
//       code: code.toString(),
//       keyword,
//     });
//     this.cookies.set('customer_record_id', recordId, { maxAge: RECORD_ID_EXPIRE_TIME });
//     this.stopLoading();
//   }
//
//   authId = '';
//
//   @action
//   public async sendRequestHomeTaxKakaoLoginInfo(id: string) {
//     if (id === '') {
//       alert('상담 신청 정보가 만료되었습니다. 다시 신청해주세요.');
//       return;
//     }
//     this.startLoading();
//     const existingRequest = await this.requestRepository.findOneRequestWithRequestId(id);
//     if (existingRequest != null) {
//       await this.requestRepository.updateTaxagentHomeTaxKakaoLoginInfo({
//         id: existingRequest.id,
//         personalNumber: this.personalNumber,
//       });
//
//       const result = await getAuthIdFromKakaoLogin({
//         name: this.name,
//         phone: this.phone,
//         ssn1: this.personalNumberFront,
//         ssn2: this.personalNumberEnd,
//       });
//
//       if (result.indexOf('incorrect') !== -1) {
//         this.stopLoading();
//         return 'info error';
//       }
//       this.authId = result;
//
//       this.stopLoading();
//       return;
//     }
//     this.stopLoading();
//   }
//
//   @action
//   public async getBasicInfoWithId(id: string) {
//     if (id == null) {
//       return;
//     }
//     this.startLoading();
//     try {
//       if (this.name === '') {
//         const result = await this.requestRepository.findOneRequestWithRequestId(id);
//         this.name = result?.name ?? '';
//         this.phone = result?.phone ?? '';
//       }
//     } finally {
//       this.stopLoading();
//     }
//     // return;
//   }
//
//   @action
//   public async sendRequestHomeTaxLoginInfo(id: string) {
//     if (id === '') {
//       alert('상담 신청 정보가 만료되었습니다. 다시 신청해주세요.');
//       return;
//     }
//     const existingRequest = await this.requestRepository.findOneRequestWithRequestId(id);
//     if (existingRequest != null) {
//       await this.requestRepository.updateTaxagentHomeTaxLoginInfo({
//         id: existingRequest.id,
//         homeTaxId: this.homeTaxId,
//         homeTaxPassword: this.homeTaxPw,
//       });
//
//       const exist_res = await axios.post(process.env.REACT_APP_API_BASE_URL + '/taxagent/exist-code', {
//         phone: this.phone,
//       });
//       const code = exist_res.data !== '' ? exist_res.data : existingRequest.code;
//       // await this.kakaoMessageSender.sendConfirmRequestMessage(this.name, this.phone, this.addLinkToCode(code));//언제 보내지..?
//
//       try {
//         await this.crawlingRepository.addCrawlingWithRecordId(existingRequest.id);
//       } catch (e) {
//         console.log(e);
//       }
//     }
//   }
//
//   @action
//   public async sendConfirmRequestMessage(id: string) {
//     if (id === '') {
//       alert('상담 신청 정보가 만료되었습니다. 다시 신청해주세요.');
//       return;
//     }
//     const existingRequest = await this.requestRepository.findOneRequestWithRequestId(id);
//
//     if (existingRequest != null) {
//       const exist_res = await axios.post(process.env.REACT_APP_API_BASE_URL + '/taxagent/exist-code', {
//         phone: this.phone,
//       });
//       const code = exist_res.data !== '' ? exist_res.data : existingRequest.code;
//       await this.kakaoMessageSender.sendConfirmRequestMessage(this.name, this.phone, this.addLinkToCode(code));
//     }
//   }
//
//   @observable
//   crawlInfo?: TotalIncomes;
//
//   @action
//   public async crawlHomeTaxLoginInfo(id: string) {
//     if (id === '') {
//       alert('상담 신청 정보가 만료되었습니다. 다시 신청해주세요.');
//       return;
//     }
//     const existingRequest = await this.requestRepository.findOneRequestWithRequestId(id);
//     if (existingRequest != null) {
//       await this.requestRepository.updateTaxagentHomeTaxLoginInfo({
//         id: existingRequest.id,
//         homeTaxId: this.homeTaxId,
//         homeTaxPassword: this.homeTaxPw,
//       });
//
//       // const exist_res = await axios.post(process.env.REACT_APP_API_BASE_URL + '/taxagent/exist-code', {
//       //   phone: this.phone,
//       // });
//       // const code = exist_res.data !== '' ? exist_res.data : existingRequest.code;
//       // await this.kakaoMessageSender.sendConfirmRequestMessage(this.name, this.phone, this.addLinkToCode(code));//언제 보내지..?
//
//       this.crawlInfo = await this.crawlingRepository.getCrawlingInfoWithRecordId(existingRequest.id);
//       return this.crawlInfo;
//     }
//   }
//
//   @action
//   public async sendRequestUploadFileInfo() {
//     this.startLoading();
//     const existingRequest = await this.requestRepository.findOneRequestWithBasicInfo(this.phone, this.name);
//     if (existingRequest != null) {
//       await this.requestRepository.updateTaxagentUploadFileInfo({
//         id: existingRequest.id,
//         files: this.noticeFiles.map(file => file.url),
//       });
//
//       const exist_res = await axios.post(process.env.REACT_APP_API_BASE_URL + '/taxagent/exist-code', {
//         phone: this.phone,
//       });
//       const code = exist_res.data !== '' ? exist_res.data : existingRequest.code;
//       await this.kakaoMessageSender.sendConfirmRequestMessage(this.name, this.phone, this.addLinkToCode(code));
//
//       this.stopLoading();
//       return;
//     }
//     this.stopLoading();
//   }
//
//   addLinkToCode(code: string): string {
//     const site = process.env.REACT_APP_COMPANY_SITE!.replace('https://', '');
//     return `${code}\n⦁ 고유링크 : ${site}/code/${code}\n\n(고유링크로 신청 시 추천인코드 자동 입력)`;
//   }
//
//
//   @action
//   openSuccessPopUp() {
//     this.isShowSuccessPopUp = true;
//   }
//
//   @action
//   public checkRequest(): string {
//     if (this.name === '') {
//       return '이름을 입력해주세요.';
//     }
//
//     if (this.phone === '') {
//       return '전화번호를 입력해주세요.';
//     }
//
//     return 'success';
//   }
//
//   @action
//   public checkHomeTaxKakaoRequest(): string {
//     if (this.name === '') {
//       return '이름을 입력해주세요.';
//     }
//
//     if (this.phone === '') {
//       return '전화번호를 입력해주세요.';
//     }
//
//     if (this.personalNumberFront === '' || this.personalNumberEnd === '') {
//       return '주민등록번호를 입력해주세요.';
//     }
//
//     if (this.personalNumberFront.length !== 6) {
//       return '주민등록번호 앞자리를 확인해주세요.';
//     }
//
//     if (this.personalNumberEnd.length !== 7) {
//       return '주민등록번호 뒷자리를 확인해주세요.';
//     }
//
//     // if (!this.isPersonalNumberRight) {
//     //   return '올바른 주민등록번호를 입력해주세요.';
//     // }
//
//     return 'success';
//   }
//
//   @action
//   startLoading() {
//     this.isLoading = true;
//   }
//
//   @action
//   stopLoading() {
//     this.isLoading = false;
//   }
//
//   @observable
//   isDependents: boolean = false;
//
//   @action
//   setIsDependents(value: boolean) {
//     this.isDependents = value;
//   }
//
//   @observable
//   personalNumberFront: string = '';
//
//   @observable
//   personalNumberEnd: string = '';
//
//   @action
//   setPersonalNumberFront(value: string) {
//     this.personalNumberFront = value;
//   }
//
//   @action
//   setPersonalNumberEnd(value: string) {
//     this.personalNumberEnd = value;
//   }
//
//   @computed
//   get personalNumber(): string {
//     return this.personalNumberFront + '-' + this.personalNumberEnd;
//   }
//
//   // @computed
//   // get isPersonalNumberRight(): boolean {
//   //   const multiNumber = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5];
//   //   const personalNumber = this.personalNumberFront + this.personalNumberEnd;
//   //   var sum = 0;
//   //   for (var i = 0; i < 12; i++) {
//   //     const multi = parseInt(personalNumber[i]) * multiNumber[i];
//   //     sum = sum + multi;
//   //   }
//   //   const lastNumber = (11 - (sum % 11)) > 9 ? (1 - (sum % 11)) : (11 - (sum % 11));
//   //   console.log(lastNumber);
//   //   return parseInt(personalNumber[12]) === lastNumber;
//   // }
//
//   @observable
//   homeTaxId: string = '';
//
//   @observable
//   homeTaxPw: string = '';
//
//   @action
//   setHomeTaxId(value: string) {
//     this.homeTaxId = value;
//   }
//
//   @action
//   setHomeTaxPw(value: string) {
//     this.homeTaxPw = value;
//   }
//
//   @action
//   public async loginHometax() {
//     this.startLoading();
//     try {
//       const userId = this.homeTaxId;
//       const pswd = this.homeTaxPw;
//
//       const result = await axios.post(
//         HOMETAX_LOGIN_API_URL,
//         {
//           userId,
//           pswd,
//         }
//       );
//       return result.data.body === '"Success"';
//     } finally {
//       this.stopLoading();
//     }
//   }
//
//   @observable
//   isTermsOfService: boolean = false;
//
//   @action
//   setTermsOfService(value: boolean) {
//     this.isTermsOfService = value;
//   }
//
//   @action
//   closeAllPopUp() {
//     this.isShowSuccessPopUp = false;
//     this.isLateTimePopUp = false;
//   }
//
//   @action
//   async getRequestInfo(id: string) {
//     this.startLoading();
//     try {
//       const result = await this.requestRepository.findOneRequestWithRequestId(id);
//       this.name = result!.name;
//       this.phone = result!.phone;
//     } catch (e) {
//       alert('정보를 찾을 수 없습니다.');
//     } finally {
//       this.stopLoading();
//     }
//   }
// }
