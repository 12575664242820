import React, { useState } from 'react';
import * as _ from 'lodash';
// @ts-ignore
import { Document, Page } from 'react-pdf';
import { css } from 'aphrodite';

import { CreateStyle } from '../../shared/presentation/shared/CreateStyle';
// import { Text, View, StyleSheet } from '@react-pdf/renderer';

// const MANUAL_PDF = 'https://companylabs.s3.ap-northeast-2.amazonaws.com/%EB%B2%95%EC%9D%B8%EC%84%A4%EB%A6%BD%EC%A7%80%EC%9B%90%EC%84%BC%ED%84%B0_%EC%95%88%EB%82%B4%EB%AC%B8.pdf';

function Manual() {
  const [numPages, setNumPages] = useState(0);

  function onDocumentLoadSuccess({ numPages }: { numPages: any}) {
    setNumPages(numPages);
  }

  return (
    <div className={css(styles.wrapper)}>
      <Document
        file={'/manual.pdf'}
        onLoadSuccess={onDocumentLoadSuccess}
        style={styles.page}
        width={700}
      >
        {_.range(numPages).map((v, index) => (
          <Page
            pageNumber={v+1}
            className={css(styles.page)}
            width={700}
            key={'page-' + index}
          />
        ))}
      </Document>
    </div>

  );
}

export default Manual;

const styles = CreateStyle({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#353C49',
    overflow: 'hidden',
  },
  page: {
    ':nth-child(1n) > canvas': {
      '@media(max-width: 700px)': {
        width: '100%',
        height: 'calc(100vw * 1.414)',
      },
    }
  }
}, false, false, { isNoRatio: true });
