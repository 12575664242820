import { injectable } from 'inversify';
import AxiosWrapper from '../AxiosWrapper';

import { BUILD_BASE_URL, STARTBIZ_BASE_URL } from '../Constants';

@injectable()
export class StartbizCustomerService {
  async getIsExistCode(code: string): Promise<boolean> {
    try {
      const { data } = await AxiosWrapper.get(`/realtime-cs/startbiz/code/${code}/RAW2`);
      return data != null;
    } catch (e) {
      return false;
    }
  }
}

@injectable()
export class BuildCustomerService {
  async getIsExistCode(code: string): Promise<boolean> {
    try {
      const { data } = await AxiosWrapper.get(`/realtime-cs/mybuild/code/${code}/RAW2`);
      return data != null;
    } catch (e) {
      return false;
    }
  }
}
