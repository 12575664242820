import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { robotoRegular, spoqa } from '../../../../shared/css/css-utils';
import { Image } from '../../../../shared/css/Image';
import { Assets } from '../../shared/Assets';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { DesktopBr } from '../../../../shared/presentation/DesktopBr';
import { NotDesktopBr } from '../../../../shared/presentation/NotDesktopBr';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';

const BENEFIT_LIST = ['무료법인전환', '검증된 세무사', '법인 필수 서비스\n특가 제공'];

function Benefit() {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const styles = BenefitStyle(isMobile, isTablet);

  return (
    <div className={css(styles.background)} id={'section_9'}>
      <div className={css(styles.leftBox)}>
        <div className={css(styles.firstFont)}>
          세가지 혜택을 한번에 챙기세요
        </div>
        <div className={css(styles.secondFont)}>
          법인전환지원센터에서는 무료법인전환 혜택과 함께<NotDesktopBr/> 초기 컨설팅,<DesktopBr/>
          사업자를 위한 특별한 혜택까지 제공해 드립니다.
        </div>
      </div>
      <div className={css(styles.rightBox)}>
        {BENEFIT_LIST.map((item, index) => (
          <div key={'benefit-' + index}>
            <div className={css(styles.benefitBox)}>
              <Image src={Assets.Benefits[index]} className={css(styles.imageStyle)}
                     alt="혜택"/>
              <div className={css(styles.benefitTextBox)}>
                <div className={css(styles.benefitFont)}>
                  {'BENEFIT ' + (index + 1)}
                </div>
                <div className={css(styles.titleFont)}>
                  {item}
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className={css(styles.footerBox)}>
          *법인 필수 서비스 : 마케팅, 홈페이지·APP, 공유오피스, 법인 차량
        </div>
      </div>
    </div>
  );
}

export default Benefit;

const BenefitStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    height: '666px/640px',
    backgroundColor: '#051a43',
    borderBottom: '/solid 1px rgba(255, 255, 255, 0.3)',

    display: 'flex',
    flexDirection_: 'column/',
    alignItems_: '/@center',

    fontFamily: spoqa,

    paddingTop: '31px/117px',
    paddingLeft: '22px/272px',
    paddingRight: '22px/',
  },
  leftBox: {
    marginRight: '/81px',
  },
  firstFont: {
    fontWeight: 300,
    fontSize: '24px/38px',
    lineHeight: '1.42/1.37',
    color: 'white',

    marginBottom: '10px/13px',
  },
  secondFont: {
    fontWeight: 400,
    fontSize: '14px/18px',
    lineHeight: 1.72,
    color: '#838ba3',

    marginBottom: '42px/',
  },
  rightBox: {
    width: '/776px',
    height: '100%',

    display: 'flex',
    flexDirection_: 'column/',
    position: 'relative',
  },
  benefitBox: {
    width: '370px/237px',
    height: '146px/237px',

    borderTop: 'solid 1px rgba(131, 139, 163, .5)/',

    paddingTop: '20px/',

    marginRight: '/24px',
    position: 'relative',
  },
  imageStyle: {
    position_: 'absolute/',

    width: '86px/237px',
    height: '107px/237px',
  },
  benefitTextBox: {
    display_: '/flex',
    flexDirection: 'column',

    marginTop: '/21px',
    marginRight: '/81px',
    fontSize: '21px/28px',
  },
  benefitFont: {
    fontFamily: robotoRegular,
    fontSize: '12px/17px',
    letterSpacing: '1.5px/2px',
    color: '#06b3cb',
    textAlign_: 'right/',

    marginRight: '/21px',
    marginBottom: '/8px',
  },
  titleFont: {
    fontWeight: 300,
    fontSize: '19px/28px',
    lineHeight: '1.53/1.39',
    color: 'white',
    whiteSpace: 'pre',
    textAlign_: 'right/',
  },
  footerBox: {
    color: '#838BA3',
    fontWeight: 300,

    position: 'absolute',
    fontSize: '13px/19px',
    bottom: '38px/46px',
    letterSpacing: '-0.2px/',
  },
}, isMobile, isTablet);
