import { CorpMember, IAddress, IRegistrationNumber, positionOptions } from '../shared/domain/CustomerConstant';

export function registrationToString(value?: IRegistrationNumber): string {
  if (value == null) {
    return '';
  }
  return `${value.front}-${value.back}`;
}

export function stringToRegistration(value?: string): IRegistrationNumber {
  if (value == null) {
    return { front: '', back: '' };
  }
  const [front, back] = value.split('-');
  return { front, back };
}

export function createInnerHTMLForRegNumber(value: string): [{ __html: string }, boolean] {
  const VALID_LENGTH = 14;
  if (value.length < VALID_LENGTH) {
    return [{ __html: `<span>${value}<br/>주민등록번호를 제대로 입력해주세요. [${value.length} => 14]</span>` }, false];
  }
  return [{ __html: value }, true];
  // const FRONT_LENGTH = 6;
  // const BACK_LENGTH = 7;
  // const regex = /([^-]*?)-(.*)/g
  // const [, front, back] = regex.exec(value) ?? [];
  // const isValid = front?.length === FRONT_LENGTH && back?.length === BACK_LENGTH;
  // return [
  //   {__html: `${getWarningNum(front, FRONT_LENGTH)} - ${getWarningNum(back, BACK_LENGTH)}`},
  //   isValid
  // ];
}

export function createInnerHTMLForAddress(address: IAddress): [{ __html: string }, boolean] {
  if (address.main === '') {
    return [{ __html: '<span>주소를 입력해주세요</span>' }, false];
  }

  if (address.detail01 === '' || address.detail02 === '') {
    return [{ __html: address.main + '\n<span>상세주소 및 건물명을 입력해주세요</span>' }, false];
  }
  return [{ __html: `${address?.main}\n${address?.detail01} ${address?.detail02}` }, true];
}

export function sortByCapital(a: CorpMember, b: CorpMember): number {
  const valueA = (a.capital ?? 0);
  const valueB = (b.capital ?? 0);
  if (valueA > valueB) {
    return -1;
  }
  if (valueA < valueB) {
    return 1;
  }
  return 0;
}

export function sortByPosition(a: CorpMember, b: CorpMember): number {
  const index01 = a.position ? positionOptions.indexOf(a.position) : 100;
  const index02 = b.position ? positionOptions.indexOf(b.position) : 100;
  if (index01 < index02) {
    return -1;
  }
  if (index01 > index02) {
    return 1;
  }
  return 0;
}
