import { ReactComponent as LogoIcon } from './assets/svgs/logo.svg';

import AddService from './assets/jpgs/addService.jpg';
import Benefit01 from './assets/jpgs/benefit_1.jpg';
import Benefit02 from './assets/jpgs/benefit_2.jpg';
import BenefitBackground from './assets/jpgs/benefit_background.jpg';
import Free from './assets/pngs/free.png';
import KakaoIn1 from './assets/pngs/kakao_in1.png';
import KakaoIn2 from './assets/pngs/kakao_in2.png';
import KakaoIn3 from './assets/pngs/kakao_in3.png';
import Menu from './assets/pngs/menu.png';
import Press from './assets/pngs/press.png';
import Quotation from './assets/pngs/quotation.png';
import Review01 from './assets/jpgs/review01.jpg';
import Review02 from './assets/jpgs/review02.jpg';
import Review03 from './assets/jpgs/review03.jpg';
import ServiceBackground from './assets/jpgs/service_background.jpg';
import SimpleBackground from './assets/pngs/simple_background.png';
import SupportBackground from './assets/jpgs/support-background.jpg';
import SupportBackgroundMobile from './assets/jpgs/support-background-m.jpg';
import AbilityBackground from './assets/jpgs/ability-background.jpg';
import Award01 from './assets/pngs/award1.png';
import Award02 from './assets/pngs/award2.png';
import BlogWhite from './assets/pngs/blog-white.png';
import CallLogo from './assets/pngs/call-logo.png';
import PhoneFrame from './assets/pngs/phone-frame.png';
import QuotationGray from './assets/pngs/quotation-gray.png';
import RequestDesk from './assets/pngs/request-desk.png';
import RequestMobile from './assets/pngs/request-mobile.png';
import RequestTablet from './assets/pngs/request-tablet.png';
import ReviewMobile01 from './assets/jpgs/review-mobile01.jpg';
import ReviewMobile02 from './assets/jpgs/review-mobile02.jpg';
import ReviewMobile03 from './assets/jpgs/review-mobile03.jpg';
import ReviewTablet01 from './assets/jpgs/review-tablet01.jpg';
import ReviewTablet02 from './assets/jpgs/review-tablet02.jpg';
import ReviewTablet03 from './assets/jpgs/review-tablet03.jpg';
import Step01 from './assets/pngs/step-1.png';
import Step02 from './assets/pngs/step-2.png';
import Step03 from './assets/pngs/step-3.png';
import Step04 from './assets/pngs/step-4.png';
import StepArrow from './assets/pngs/step-arrow.png';
import TaxAccounter01 from './assets/pngs/month.png';
import AdsBackDesk from './assets/jpgs/ads-back-desk.jpg';
import MainVideo from './assets/main-video.mp4';
import MainBackgroundDesk from './assets/pngs/main-background.png';
import MainBackgroundMobile from './assets/jpgs/main-background-mobile.jpg';
import AdsPopUp from './assets/pngs/ads-popup.png';

export const Assets = {
  AdsPopUp,
  MainVideo,
  LogoIcon,
  AddService,
  Benefit01,
  Benefit02,
  BenefitBackground,
  Free,
  KakaoIn1,
  KakaoIn2,
  KakaoIn3,
  Menu,
  // News01,
  // News02,
  // News_01,
  // News_02,
  Press,
  Quotation,
  ServiceBackground,
  SimpleBackground,
  SupportBackground,
  SupportBackgroundMobile,
  AbilityBackground,
  Award01,
  Award02,
  BlogWhite,
  CallLogo,
  MainBack: (isMobile: boolean) => isMobile ? MainBackgroundMobile : MainBackgroundDesk,
  // News01Mobile,
  // News02Mobile,
  // News01Tablet,
  // News02Tablet,
  PhoneFrame,
  QuotationGray,
  // RequestBack: (isMobile: boolean, isTablet: boolean) =>
  //   isMobile
  //     ? RequestBackgroundMobile
  //     : isTablet
  //     ? RequestBackgroundTablet
  //     : RequestBackground,
  // RequestBackgroundMobile,
  RequestDesk,
  RequestMobile,
  RequestTablet,
  ReviewDesk: [
    Review01,
    Review02,
    Review03,
  ],
  ReviewMobile: [
    ReviewMobile01,
    ReviewMobile02,
    ReviewMobile03,
  ],
  ReviewTablet: [
    ReviewTablet01,
    ReviewTablet02,
    ReviewTablet03,
  ],
  Steps: [
    Step01,
    Step02,
    Step03,
    Step04,
  ],
  StepArrow,
  TaxAccounter01,
  AdsBackDesk,
};
