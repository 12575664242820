import { injectable } from 'inversify';

import { IPaymentInput, Payment } from './Payment';
import { IAM_PORT_KG } from './IamPortContants';

@injectable()
export class IamPortPayment implements Payment {
  IMP: any;
  constructor() {
    this.IMP = window.IMP;
    this.IMP.init(process.env.REACT_APP_IAM_PORT_MEMBERSHIP_ID_CODE);
  }

  async request({ buyer, amount, name, redirect, callback, merchantId,  payMethod}: IPaymentInput): Promise<any> {
    this.IMP.request_pay({
      pg : IAM_PORT_KG,
      pay_method : payMethod ?? 'card',
      merchant_uid : merchantId,
      name,
      amount,
      buyer_email : buyer.email, // 'iamport@siot.do',
      buyer_name : buyer.name, //'구매자',
      buyer_tel : buyer.tel, // '010-1234-5678',
      // buyer_addr : buyer.address, // '서울특별시 강남구 삼성동',
      // buyer_postcode : buyer.postcode, // '123-456',
      m_redirect_url : redirect, // 예: https://www.my-service.com/payments/complete/mobile
      niceMobileV2 : true // 신규 모바일 버전 적용 시 설정
    }, (response: any) => {
      callback?.(response);
    });
  }
}
