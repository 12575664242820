import React from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { injectable } from 'inversify';

import { IPaymentCompleteOutput, IPaymentView } from './PaymentFrame';

@injectable()
export class IamPortCompleteFactory {
  create(): React.FC<IPaymentView> {
    return IamPortComplete;
  }
}

interface IamPortOutput {
  imp_uid?: string; // 'imp_491760343246'
  merchant_uid?: string; // 'merchant_1647147760235'
  imp_success?: boolean; // 'false'
  error_msg?: string;
}

export const IamPortComplete: React.FC<IPaymentView> = ({ CompleteView }) => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  return <CompleteView output={mapIamPortOutputToPaymentOutput(query)}/>
}

function mapIamPortOutputToPaymentOutput(entity: IamPortOutput): IPaymentCompleteOutput {
  return {
    id: entity.imp_uid,
    merchantId: entity.merchant_uid,
    isSuccess: entity.imp_success === true,
    errorMessage: entity.error_msg ?? '',
  }
}
