import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';

import { ReactComponent as MenuIcon } from './presentation/icons/menu.svg';
import { totalScreenState } from './presentation/MobileFrame';
import { menuPage } from './presentation/HistoryHelper';
import { ButtonStyles } from './ButtonStyles';

export function MenuButton() {
  const { isMobile, isTablet, isTop } = useRecoilValue(totalScreenState);

  const styles = ButtonStyles({
    isMobile,
    isTablet,
  })

  return (
    <React.Fragment>
      {(isTablet || isMobile) && (
        <Link
          className={css(styles.button, isTop && styles.button_top)}
          to={menuPage}
        >
          <MenuIcon className={css(styles.buttonIcon)}/>
        </Link>
      )}
    </React.Fragment>
  );
}
