import React from 'react';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { kakaoPage } from '../../../shared/presentation/HistoryHelper';
import { ReactComponent as Kakao } from '../../../shared/presentation/icons/kakao.svg';
import { totalScreenState } from '../../../shared/presentation/MobileFrame';
import { RenewalButtonStyle } from '../../../shared/RenewalButtonStyle';

export const KakaoButton = () => {
  const {isMobile, isTablet, isDesktop, isTop} = useRecoilValue(totalScreenState);
  const styles = RenewalButtonStyle({
    isMobile,
    isTablet
  });

  return (
    <>
      {(isTop || isDesktop) && (
          <Link
            className={css(styles.button, isTop && styles.button_top)}
            to={kakaoPage()}
            id={'kakao_request_button'}
          >
            <Kakao className={css(styles.buttonIcon)}/>
            {isDesktop && <p className={css(styles.text, styles.iconSpace)}>카카오톡 상담</p>}
          </Link>
        )}
    </>
  );
};
