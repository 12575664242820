import React from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';
import { useAtomValue } from 'jotai';
import * as qs from 'qs';

import { ReactComponent as HeaderLogo } from './assets/svgs/logo.svg';
import { Assets } from './Assets';
import { KakaoButton } from './KakaoButton';
import { MenuButton } from './MenuButton';
import { RequestButton } from './RequestButton';

import { IHistory, isShowMenu, requestPage, } from './presentation/HistoryHelper';
import { totalScreenState } from './presentation/MobileFrame';
import { moveToHome } from './helper/Util';
import { CreateStyle } from './presentation/shared/CreateStyle';
import { spoqa } from './css/css-utils';
import { RenewalLogo } from './presentation/header/RenewalLogo';
import { RenewalButtonStyle } from './RenewalButtonStyle';
import { keywordEditedAtom } from './presentation/KeywordAtom';

function Header() {
  const {isMobile, isTablet, isDesktop, isTop} = useRecoilValue(totalScreenState);
  const history = useHistory<IHistory>();
  const isMenu = isShowMenu(history) && !isDesktop;

  const headerStyle = HeaderStyles({
    isTop: isTop && !isMenu, isMobile, isTablet
  });
  const buttonStyles = RenewalButtonStyle({isMobile, isTablet});
  const customStyles = CustomStyles({
    isMobile, isTablet, isTop
  });

  const keywordSource = useAtomValue(keywordEditedAtom);
  const openTransbiz = () => {
    window.open(`https://k-transbiz.org/?${qs.stringify(keywordSource)}`);
  };

  const openManual = () => {
    window.open('https://manual.k-startbiz.org');
  };

  if (!isDesktop && isShowMenu(history)) {
    return (
      <></>
    );
  }

  return (
    <>
      {isDesktop ? (
        <div
          className={css(headerStyle.header, headerStyle.transitionStyle)}
          id="header"
        >
          <div className={css(headerStyle.headerFirstBox)}>
            <div className={css(headerStyle.logoIcon)}>
              <RenewalLogo LogoImage={Assets.LogoIcon} baseColor={'#002933'}/>
            </div>
            <div className={css(headerStyle.buttonBox)}>
              <div
                className={css(buttonStyles.button, isTop && buttonStyles.button_top)}
                onClick={() => window.open('https://blog.k-startbiz.org')}
              >
                블로그
              </div>
              <div
                className={css(buttonStyles.button, isTop && buttonStyles.button_top)}
                onClick={openManual}
              >
                이용 매뉴얼
              </div>
              <div
                className={css(buttonStyles.button, isTop && buttonStyles.button_top)}
                onClick={openTransbiz}
              >
                개인사업자 법인전환
              </div>
            </div>
          </div>
          <div className={css(headerStyle.buttonBox)}>
            <KakaoButton/>
            <RequestButton/>
            {!isTop && (
              <>
                <Link
                  className={css(customStyles.button)}
                  to={requestPage()}
                  id={'request_button'}
                  rel={'request'}
                >
                  <p className={css(headerStyle.text)}>
                    {'무료법인설립 신청'}
                  </p>
                </Link>
              </>
            )}
          </div>
        </div>
      ) : (
        <div
          className={css(headerStyle.header, headerStyle.transitionStyle)}
          id="header"
        >
          <div
            className={css(headerStyle.logo)}
            onClick={() => moveToHome(history)}
          >
            <HeaderLogo className={css(headerStyle.logoIcon)}/>
          </div>
          <div className={css(headerStyle.buttonBox)}>
            <KakaoButton/>
            <RequestButton/>
            <MenuButton/>
          </div>
        </div>
      )}
    </>
  );
}

export default withRouter(Header);

interface HeaderStyleProps {
  isMobile: boolean,
  isTablet: boolean,
  isTop: boolean,
  baseColor?: string,
}

export const HeaderStyles = (
  {
    isMobile,
    isTablet,
    isTop,
    baseColor = '#002933',
  }: HeaderStyleProps
) => CreateStyle({
  header: {
    zIndex: 50,
    position: 'fixed',
    width: '100%',
    height: 'calc(env(safe-area-inset-top) + 60px)/80px@calc(env(safe-area-inset-top) + 78px)',
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: isTop
      ? 'rgba(255, 255, 255, 0)'
      : `rgba(255, 255, 255, 0.9)/rgba(255, 255, 255, 1)@rgba(255, 255, 255, 0.9)`,

    padding: 'calc(env(safe-area-inset-top) + 5px) 5px 5px 5px/@calc(env(safe-area-inset-top) + 25px) 25px 25px 25px',
  },
  transitionStyle: {
    transition: '.3s',
  },
  headerFirstBox: {
    display: 'flex',
    alignItems: 'center',
    height: '/100%',
  },
  logo: {
    height: '23px/@30px',
    cursor: 'pointer',
    marginLeft: '15px/@5px',
  },
  logoIcon: {
    width: '150px/@186px',
    height: '100%/21px',
    fill: isTop ? 'white' : baseColor,
  },
  buttonBox: {
    display: 'inline-flex',
    height: '100%',
  },
  onlyKakao: {
    width: `23px/25px@28px`,
    height: `23px/25px@28px`,
  },
  text: {
    whiteSpace: 'pre',
  },
  closeIcon: {
    marginRight: '15px/',
    marginTop: '5px/',
    cursor: 'pointer',
  },
}, isMobile, isTablet, {isNoRatio: true});

const CustomStyles = (
  {
    isMobile,
    isTablet,
    isTop,
  }: {
    isMobile: boolean,
    isTablet: boolean,
    isTop: boolean,
  }) => CreateStyle({
  button: {
    width: '50px/',
    height: '100%',
    border: 'none',
    borderLeft: `solid 1px ${isTop ? 'rgba(255, 255, 255, 0.25)' : '#d8d8d8'}`,
    padding: '0/0 42px',

    display: 'flex',
    alignItems: 'center',
    justifyContent_: 'center/flex-start',

    backgroundColor: '#002933',
    fontFamily: spoqa,
    fontWeight: 300,
    fontSize: '3.14vw/17px',
    lineHeight: '/20px',
    color: 'white',

    cursor: 'pointer',
  },
}, isMobile, isTablet, {isNoRatio: true});
