import Cookies from 'universal-cookie';
import { atom } from 'recoil';
import { inject, injectable } from 'inversify';
import axios from 'axios';

import { TAX_RETURN_KAKAO_LOGIN_URL } from '../helper/KakaoLoginHelper';
import { ID_NOT_EXIST } from './TaxagentRequestStore';
import { TAX_RETURN_REQUEST_REPOSITORY, TaxReturn, TaxReturnReopository } from '../domain/TaxReturnReopository';
import { TaxReturnForm } from './TaxReturnForm';
import { sleep } from '../helper/Util';

export const TAX_RETURN_SERVICE = Symbol('TAX_RETURN_SERVICE');

export const taxReturnState = atom<TaxReturnForm>({
  key: 'taxReturnState',
  default: new TaxReturnForm(),
});

@injectable()
export class TaxReturnService {
  cookies = new Cookies();

  @inject(TAX_RETURN_REQUEST_REPOSITORY)
  private readonly repository!: TaxReturnReopository;

  public async processRequestLoginStep(input: TaxReturnForm, callback: (request: any) => void): Promise<string> {
    const { spreadSheetId, request } = await requestLogin(input);
    callback(request);
    return spreadSheetId;
  }

  async saveUser({ name, phone, socialSecurityNo: ssn, spreadSheetId }: TaxReturnForm, callback: (recordId: any) => void): Promise<string> {
    const recordId = await this.repository.add({ name, phone, ssn, spreadSheetId });
    callback(recordId);
    return recordId;
  }

  public async saveComplete(recordId: string) {
    return await this.repository.update({ id: recordId, state: '인증완료' });
  }

  public async fetchBasicInfoFromCookie({ setInfo, repository }: any): Promise<void> {
    const recordId = await getRecordIdWithCookie();
    const data = await repository.findOneById(recordId);
    console.log(data);
    if (!data) {
      return;
    }
    const { name, phone, ssn } = data;
    setInfo((_info: TaxReturnForm) => _info.copy().setName(name).setPhone(phone).setSsn(ssn).setRecordId(recordId));
  }

  public async getBasicInfoWithId(id: string): Promise<TaxReturn> {
    if (id == null) {
      throw new Error(ID_NOT_EXIST);
    }
    return this.repository.findOneById(id);
  }
}

async function requestLogin(input: TaxReturnForm): Promise<{ spreadSheetId: string, request: any }> {
  const { data } = await axios.post(TAX_RETURN_KAKAO_LOGIN_URL, input.toJson());
  return data;
}

async function getRecordIdWithCookie(): Promise<string> {
  const cookies = new Cookies();
  for (let i = 0; i < 5; i++) {
    console.log('check... ' + i);
    const recordId = cookies.get('tax_return_record_id');
    if (recordId) {
      return recordId;
    }
    await sleep(200);
  }

  console.log('out');
  throw new Error(ID_NOT_EXIST);
}
